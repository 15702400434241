import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { configuration } from 'config/constants';
import { LoadingPage } from 'components/common/LoadingPage';
import { loadScript } from 'services/loadScript';
import { ISGWTConnectOpenIDStandardClaims, SGWTConnectCore } from '@sgwt/connect-core';
import { useAppContext } from './store';
import { setUserInfo } from 'store/UserInfo/UserInfoThunk';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-connect': {
                    scope?: string;
                    'authorization-endpoint'?: string;
                    'client-id'?: string;
                    'acr-values'?: string;
                };
            }
        }
    }
    interface Window {
        SGWTWidgetConfiguration: { environment: SgConnectEnvironment }
    }
}

interface ISGWTConnectWidget extends HTMLElement {
    sgwtConnect?: SGWTConnectCore;
}

export enum SgConnectEnvironment {
    Homologation = 'homologation',
    Production = 'production',
}

export const getSgwtWidget = (): ISGWTConnectWidget | null =>
    document.querySelector('sgwt-connect') as ISGWTConnectWidget;

export const SgwtProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthent, setIsAuthent] = useState(false);
    const { dispatch } = useAppContext();

    const login = useCallback(() => {
        const widget = getSgwtWidget()!;
        const sgwtConnect = widget.sgwtConnect!;
        widget.removeEventListener('sgwt-connect--ready', login);

        if (sgwtConnect.isAuthorized()) {
            sgwtConnect.fetchUserInfo((_, claims?: ISGWTConnectOpenIDStandardClaims) => {
                if (claims) {
                    dispatch(setUserInfo(`${claims['contact_id']}`, `${claims['mail']}`));
                }
                setIsAuthent(true);
            });
            return;
        }

        const authorizationError = sgwtConnect.getAuthorizationError();
        if (authorizationError) {
            throw authorizationError;
        }

        // redirects to sg-connect auth login page, which then in turn reloads the page on success
        // the getUserAccessRights function will then be called again
        // but either an error will have occurred
        // or the user will be authorized
        sgwtConnect.requestAuthorization();
    }, []);

    useEffect(() => {
        window.SGWTWidgetConfiguration ??= {
            environment: configuration.sgConnectEnvironment,
        };

        const widget = document.querySelector('sgwt-connect') as any;
        if (widget === null) {
            console.error('The widget sgwt-connect was not found on the page');
        } else if (widget.sgwtConnect) {
            // Widget is already initialized, so can call the login() function.
            login();
        } else {
            // Widget is not yet initialized, so we need to wait for the "ready" event.
            widget.addEventListener('sgwt-connect--ready', login);
        }

        loadScript(`${configuration.sharedWidgetBaseUrl}/widgets/sgwt-widget-polyfills/sgwt-widget-polyfill-webcomponent.js`)
            .then(() => loadScript(`${configuration.sharedWidgetBaseUrl}/widgets/sgwt-connect/v4/sgwt-connect.js`))
            .then(() => {
                loadScript(`${configuration.sharedWidgetBaseUrl}/ic-widgets/v1/ic-contact-single-picker.js`);
                loadScript(`${configuration.sharedWidgetBaseUrl}/ic-widgets/v1/ic-contact-multi-picker.js`);
            })
            .then(() => setIsLoading(false));

        return () => { widget.removeEventListener('sgwt-connect--ready', login); };
    }, []);

    return <>
        <sgwt-connect
            authorization-endpoint={configuration.authorizationEndpoint}
            client-id={configuration.clientId}
            scope={configuration.scope}
            acr-values="L1"
        />
        {(isLoading || !isAuthent) ? <LoadingPage /> : children}
    </>;
};
