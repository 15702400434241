import React, { lazy, Suspense, FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Footer } from 'components/layout/Footer';
import { Header } from 'components/common/header/Header';
import { ToastContainer } from 'react-toastify';
import { HelpCenter } from 'components/layout/HelpCenter';
import { SplashScreen } from 'components/layout/SplashScreen';
import { Loading } from 'components/common/Loading';

const SubscriptionListsPage = lazy(() => import('components/pages/subscriptionListsPage/SubscriptionListsPage'));
const SubscriptionListCreateEditPage = lazy(() => import('components/pages/subscriptionListEditPage/SubscriptionListCreateEditPage'));
const SubscriptionListHistoryPage = lazy(() => import('components/pages/subscriptionListHistoryPage/SubscriptionListHistoryPage'));
const UnsubscribePage = lazy(() => import('components/pages/subscription/UnsubscribePage'));
const UnsubscribeValidatedPage = lazy(() => import('components/pages/subscription/UnsubscribeValidatedPage'));
const SubscribePage = lazy(() => import('components/pages/subscription/SubscribePage'));
const SubscribeValidatedPage = lazy(() => import('components/pages/subscription/SubscribeValidatedPage'));
const ForbiddenPage = lazy(() => import('components/pages/error/ForbiddenPage'));

export const App: FC = () => {
	return (
		<>
			<Header />
			<Suspense fallback={<div className="d-flex justify-content-center"><Loading /></div>}>
				<Routes>
					<Route path="/" element={<SubscriptionListsPage />} />
					<Route path="/create" element={<SubscriptionListCreateEditPage />} />
					<Route path="/edit/:id" element={<SubscriptionListCreateEditPage />} />
					<Route path="/history/:id" element={<SubscriptionListHistoryPage />} />
					<Route path="/unsubscribe/:clientSubscriptionListId" element={<UnsubscribePage />} />
					<Route path="/unsubscribe-validated" element={<UnsubscribeValidatedPage />} />
					<Route path="/subscribe/:clientSubscriptionListId" element={<SubscribePage />} />
					<Route path="/subscribe-validated" element={<SubscribeValidatedPage />} />
					<Route path="/error403" element={<ForbiddenPage />} />
				</Routes>
			</Suspense>
			<Footer />

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				closeButton={false}
				icon={false}
				newestOnTop
				closeOnClick
				pauseOnFocusLoss
				draggable
				pauseOnHover
				stacked
			/>
			<HelpCenter />
			<SplashScreen />
		</>
	);
};

export default App;
