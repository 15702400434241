import React from 'react';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader/WidgetLazyLoader';
import { configuration } from 'config/constants';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-mini-footer': {
                    'id': string;
                    'no-border': boolean;
                    'mode': string;
                    'contact-us': string;
                    'type': string;
                };
            }
        }
    }
}

export const Footer: React.FC = () => {
    return <div className="footer mt-auto w-100 d-flex bg-lvl2">
        <div className="flex-grow-1">
            <WidgetLazyLoader script={`${configuration.sharedWidgetBaseUrl}/widgets/sgwt-mini-footer/v4/sgwt-mini-footer.js`}>
                <sgwt-mini-footer
                    id="sgwt-mini-footer"
                    mode="sg-markets"
                    contact-us="{'mail':'SGMarkets@sgcib.com'}"
                    no-border
                    type="compact"
                />
            </WidgetLazyLoader>
        </div>
    </div>;
};
